class NotFound extends HTMLElement {
    constructor() {
        super()
        this.innerHTML = `
        <div class="l-prose-blocks">
            <h2 class="l-prose-blocks__title">Product not found</h2>
                <p>A product with that id could not be found. Please try a different product.</p>
            </div>
        </div>
        `
    }
}


function initNotFound() {
    window.customElements.define('missing-product', NotFound);
}

export { initNotFound, NotFound }