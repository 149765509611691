import { init } from '@retailcart/shop-sdk';
import { Cart, CartControl, initCart } from './components/Cart';
import { NotFound, initNotFound } from './components/NotFound';
import { ProductDetails, initProductDetails } from './components/ProductDetails';
import { StoreListingItem, initStoreListingItem } from './components/StoreListingItem';


document.addEventListener("DOMContentLoaded", function () {
	const Sentry = window.Sentry;
	let endpoint = window.STORE_CONFIG['retailCartEndpint'];
	let sdk = init(endpoint);

	// Set up web components
	initStoreListingItem();
	initProductDetails();
	initCart();
	initNotFound();

	const errorPage = () => document.location.href = window.STORE_CONFIG['errorURL'];

	// Cart (always present)
	let cartContainer = document.querySelector('[data-cart]');
	const cart = new Cart(sdk, cartUpdateCallback);
	cartContainer.appendChild(cart);

	const cartControl = new CartControl(cart);

	const bp = getComputedStyle(document.documentElement).getPropertyValue('--bp-large');
	if (window.matchMedia(`screen and (min-width: ${bp}`).matches) {
		let navContainer = document.querySelector('.l-navigation__actions');
		navContainer.appendChild(cartControl);
	} else {
		let navContainer = document.querySelector('.l-site-header');
		navContainer.appendChild(cartControl);
	}

	const cartUpdateCallback = (cartResponse) => {
		if (!cartResponse) {
			cart.cartResponse = null;
			cartControl.count = 0;
			return;
		}
		localStorage.setItem('cartData', JSON.stringify(cartResponse));
		cart.cartResponse = cartResponse;
		cartControl.count = cartResponse.cartItems.length;
	}
	cart.cartUpdateCallback = cartUpdateCallback;
	const cartData = localStorage.getItem('cartData');
	if (!cartData) {
		sdk.getCart().then(cartUpdateCallback).catch(e => {
			cartUpdateCallback(null);
		})
	} else {
		cartUpdateCallback(JSON.parse(cartData));
	}

	// StoreListing
	let listing = document.querySelector('[data-products-listing]');
	if (listing) {
		thinking(false);
		sdk.listProducts().then(response => {
			thinking(true);
			response.products.forEach((product) => {
				let price = product.variants[0].sellPrice;
				let listingItem = new StoreListingItem(product, price);
				listing.appendChild(listingItem);
			})
		}).catch(e => {
			Sentry && Sentry.captureException(e);
			errorPage()
		});
	}
	// ProductDetails
	let productPage = document.querySelector('[data-product]');
	if (productPage) {
		const productId = productPage.dataset['product'];
		thinking(false);
		sdk.getProduct(productId).then(product => {
			thinking(true);
			let productDetails = new ProductDetails(product, sdk, cartUpdateCallback);
			productPage.appendChild(productDetails);
		}).catch(e => {
			thinking(true);
			if (e.isApiException && e.status == 404) {
				productPage.classList.remove('loading');
				let inner = new NotFound();
				productPage.appendChild(inner);
			} else {
				throw e
			}
		})
	}

	// Complete order (we're on the success page)
	let orderComplete = document.querySelector('[data-complete-order]');
	let params = new URLSearchParams(document.location.search)
	if (orderComplete || params.get('cancelled')) {
		// Reset cart and clear localStorage
		localStorage.clear();
		cart.cartResponse = null;
		cartControl.count = 0;
	}

	window.addEventListener('resize', _ => {
		if (window.matchMedia(`screen and (min-width: ${bp}`).matches) {
			let navContainer = document.querySelector('.l-navigation__actions');
			navContainer.appendChild(cartControl);
		} else {
			let navContainer = document.querySelector('.l-site-header');
			navContainer.appendChild(cartControl);
		}
	})
});


function thinking(finished) {
	if (finished) {
		document.body.classList.remove('thinking');
		document.body.style.opacity = 1;
	} else {
		document.body.classList.add('thinking');
		document.body.style.opacity = 0.2;
	}
}
