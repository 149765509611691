
import { shopImageURL } from '../includes/shopUtils';

class StoreListingItem extends HTMLElement {
    constructor(product, price) {
        super()
        const href = `${window.location.origin}${window.location.pathname}${product.slug}/`;

        const imageSrc = shopImageURL(product.imageId, true);
        this._root = this.attachShadow({ mode: "open" });
        this._root.innerHTML = `
            <a href="${href}" part='container'>
                <div part='image' style="background-image: url('${imageSrc}')"></div>
                <h3 part='title'>${product.name}</h3>
                <span part='price'>$${price}</span>
                <p part='description'>${product.shortDescription}</p>
            </a>
        `
    }
}

function initStoreListingItem() {
    window.customElements.define('product-listing-item', StoreListingItem)
}

export { initStoreListingItem, StoreListingItem }